<template>
  <div class="row justify-content-center">
    <div class="col-10 col-lg-6">
      <div>
        <h4 class="text-center">TapGet Pyxis Demo</h4>
        <div v-if="responseStatus && responseStatus != 200" class="alert alert-danger" role="alert">
            Error setting status
        </div>
        <div v-if="orders.length === 0" class="alert alert-info" role="info">
            No open orders found
        </div>
        <ul class="list-group">
          <li v-for="order in orders" :key="order.uuid" class="list-group-item">
            <div class="d-flex w-100 justify-content-between mb-2">
              <h5 class="mb-1">{{ order.uuid }}</h5>
              <small>{{ order.createdAt }}</small>
            </div>
            <div class="d-flex w-100 justify-content-between">
              <p>{{ order.comment }}</p>
              <p>{{ Intl.NumberFormat('en-ID', { style: 'currency', currency: 'IDR' }).format(order.sum) }}</p>
            </div>
            <p class="mb-1">
              Pyxis ID: {{ order.externalId }}
            </p>
            <p class="mb-1">
              Status: {{ order.transaction.status }} -> {{ order.status }}
            </p>
            <div class="btn-group mt-3" role="group" aria-label="Order Actions">
              <button type="button" class="btn btn-success" @click="setStatus(order, 'SELF')" :disabled="['storno', 'refused', 'finished', 'open'].includes(order.status) || order.transaction.status === 'SELF'">SELF</button>
              <button type="button" class="btn btn-light mx-0 px-0" disabled></button>
              <button type="button" class="btn btn-success" @click="setStatus(order, 'WAIT')" :disabled="['storno', 'refused', 'finished'].includes(order.status) || order.transaction.status === 'WAIT'">WAIT</button>
              <button type="button" class="btn btn-light mx-0 px-0" disabled></button>
              <button type="button" class="btn btn-success" @click="setStatus(order, 'KTCH')" :disabled="['storno', 'refused', 'finished'].includes(order.status) || order.transaction.status === 'KTCH'">KTCH</button>
              <button type="button" class="btn btn-light mx-0 px-0" disabled></button>
              <button type="button" class="btn btn-success" @click="setStatus(order, 'COOK')" :disabled="['storno', 'refused', 'finished', 'ready'].includes(order.status) || order.transaction.status === 'COOK'">COOK</button>
              <button type="button" class="btn btn-light mx-0 px-0" disabled></button>
              <button type="button" class="btn btn-success" @click="setStatus(order, 'DONE')" :disabled="['storno', 'refused', 'finished', 'delivered'].includes(order.status) || order.transaction.status === 'DONE'">DONE</button>
              <button type="button" class="btn btn-light mx-0 px-0" disabled></button>
              <button type="button" class="btn btn-warning" @click="setStatus(order, 'PAID')" :disabled="['storno', 'refused', 'finished'].includes(order.status) || order.transaction.status === 'PAID'">PAID</button>
              <button type="button" class="btn btn-light mx-0 px-0" disabled></button>
              <button type="button" class="btn btn-danger" @click="setStatus(order, 'DELE')" :disabled="['storno', 'refused', 'finished', 'refused'].includes(order.status) || order.transaction.status === 'DELE'">DELE</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      orders: [],
      responseStatus: null
    }
  },
  computed: {
    sortedOrders () {
      return [...this.orders].sort((a, b) => a.localeCompare(b))
    }
  },
  created: function () {
    this.getOrders()
    this.timer = setInterval(this.getOrders, 5000)
  },
  methods: {
    async setStatus (order, status) {
      this.axios.post(`/pyxis/${order.externalId}/status`, { status }).then((res) => {
        this.responseStatus = res.response.status
      }).catch((err) => {
        console.log(err)
        this.responseStatus = err.response.status
      })
    },
    async getOrders () {
      const ordersRes = await this.axios.get('/pyxis/demoorders')
      const orders = ordersRes.data
      const transactionsRes = await this.axios.get('/pyxis')
      const transactions = transactionsRes.data
      for (const order of orders) {
        order.transaction = transactions.find(tr => tr.transactionno === order.externalId)
      }
      this.orders = orders
    }
  }
}
</script>

<style scoped lang="scss">
</style>
