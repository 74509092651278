import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import RouterView from '../views/Router.vue'
import Home from '../views/Home.vue'
import Free from '../views/Free.vue'
import PyxisDemo from '../views/PyxisDemo.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Registered from '../views/Registered.vue'
import Checkout from '../views/Checkout.vue'
import CheckoutThankyou from '../views/CheckoutThankyou.vue'
import VerifyAuth from '../views/VerifyAuth.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import OrganisationInvite from '../views/OrganisationInvite.vue'
import Account from '../views/Account.vue'
import Restaurants from '../views/Restaurants.vue'
import Restaurant from '../views/Restaurant.vue'
import NewLocation from '../views/location/NewLocation.vue'
import Organisation from '../views/Organisation.vue'
import Contracts from '../views/Contracts.vue'
import Error404 from '../views/error/404.vue'

import i18n from '../config/i18n'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale',
    component: RouterView,
    children: [
      {
        path: '',
        alias: 'upgrade',
        name: 'home',
        component: Home,
        meta: { auth: true }
      },
      {
        path: 'free',
        alias: 'free',
        name: 'free',
        component: Free,
        meta: { auth: false }
      },
      {
        path: 'pyxisdemo',
        alias: 'pyxisdemo',
        name: 'pyxisdemo',
        component: PyxisDemo,
        meta: { auth: false }
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: { auth: false }
      },
      {
        path: 'register',
        name: 'register',
        component: Register,
        meta: { auth: false }
      },
      {
        path: 'login/registered',
        name: 'registered',
        component: Registered,
        meta: { auth: false }
      },
      {
        path: 'checkout',
        name: 'checkout',
        component: Checkout,
        props: true,
        meta: { auth: true }
      },
      {
        path: 'checkout/thankyou',
        name: 'checkoutThankyou',
        component: CheckoutThankyou,
        props: true,
        meta: { auth: true }
      },
      {
        path: 'auth/verify/:token',
        name: 'verify',
        component: VerifyAuth,
        props: true,
        meta: { auth: false }
      },
      {
        path: 'auth/password/reset/:token',
        name: 'resetPassword',
        component: ResetPassword,
        props: true,
        meta: { auth: false }
      },
      {
        path: 'auth/organisation/invite/:token',
        name: 'organisationInvite',
        component: OrganisationInvite,
        props: true,
        meta: { auth: undefined }
      },
      {
        path: 'forgotPassword',
        name: 'forgotPassword',
        component: ForgotPassword,
        props: true,
        meta: { auth: false }
      },
      {
        path: 'account',
        name: 'account',
        component: Account,
        props: true,
        meta: { auth: true }
      },
      {
        path: 'locations',
        name: 'locations',
        component: Restaurants,
        props: true,
        meta: { auth: true }
      },
      {
        path: 'locations/new',
        name: 'newLocation',
        component: NewLocation,
        props: true,
        meta: { auth: true }
      },
      {
        path: 'locations/:id',
        name: 'restaurant',
        component: Restaurant,
        props: true,
        meta: { auth: true }
      },
      {
        path: 'organisation',
        name: 'organisation',
        component: Organisation,
        props: true,
        meta: { auth: true }
      },
      {
        path: 'contracts',
        name: 'contracts',
        component: Contracts,
        props: true,
        meta: { auth: true }
      },
      {
        path: ':pathMatch(.+)',
        name: 'Error404',
        component: Error404,
        meta: { auth: false }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default (app: any) => {
  router.beforeEach((to, _, next) => {
    const supportedLocales = ['de', 'en']
    if (!supportedLocales.includes(to.params.locale as string)) {
      return next('/en' + to.path)
    }
    if (i18n.global.locale !== to.params.locale) {
      i18n.global.locale = to.params.locale as string
    }
    return next()
  })

  app.router = router

  app.use(router)
}
